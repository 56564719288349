/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //
        // @start Form labels
        //
        var animatedFormFields = 'form:not(.no-animation) input:not([type=radio]), form:not(.no-animation) textarea';
        function addLabelClassUp(e) {
          $(e.currentTarget).siblings('label').addClass('up');
          $(e.currentTarget).closest('.ginput_container').siblings('label').addClass('up');
        }
        function removeLabelClassUp(e) {
          $(e.currentTarget).siblings('label').removeClass('up');
          $(e.currentTarget).closest('.ginput_container').siblings('label').removeClass('up');
        }
        // If coming back from comment form error/notice make sure we add the up class
        $('.comment-form input, .comment-form textarea').each(function() {
          if($(this).val()) {
            $(this).prev('label').addClass('up');
          }
        });
        // When the input is changed at all we check and if there's content in it we add the class
        $(document).on('input', animatedFormFields, function(e) {
          if(e.currentTarget.value) {
            addLabelClassUp(e);
          }
        });
        // When the input is focused we add the class unconditionally; when it unfocuses we remove the label ONLY if it's empty
        $(document).on('focus', animatedFormFields, function(e) {
          addLabelClassUp(e);
        }).on('focusout', 'form:not(.no-animation) input, form:not(.no-animation) textarea', function(e) {
          if(!e.currentTarget.value || '' === e.currentTarget.value) {
            removeLabelClassUp(e);
          }
        });
        $(document).on('gform_post_render', function(event, form_id, current_page) {
          $(this).find(animatedFormFields).trigger('input');
        });
        //
        // @end Form labels
        //

        //
        // @start Sticky nav
        //
        $('header.banner').affix({
          offset: {
            top: 30
          }
        });
        //
        // @end Sticky nav
        //

        //
        // @start Text sizes
        //
        if(Cookies.get('textSize')) {
          $('html').attr('data-text-size', Cookies.get('textSize'));
          $('.text-size').removeClass('active');
          $('.text-size[data-text-size="'+Cookies.get('textSize')+'"]').addClass('active');
        }
        $(document).on('click', '.text-size', function() {
          $('.text-size').removeClass('active');
          $(this).addClass('active');
          $('html').attr('data-text-size', $(this).attr('data-text-size'));
          Cookies.set('textSize', $(this).attr('data-text-size'), { expires: 90 });
        });
        //
        // @end Text sizes
        //


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'has_section_testimonials': {
      init: function() {
        var testimonialSwiperSelector = '.section-testimonials .swiper-container';
        $(testimonialSwiperSelector).imagesLoaded({
          background: true
        }, function() {
          var testimonialSwiper = new Swiper(testimonialSwiperSelector, {
            loop: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            uniqueNavElements: false,
            slidesPerView: 2,
            autoHeight: true,
            speed: 1000,
            keyboard: {
              enabled: true,
              onlyInViewport: false,
            },
            breakpoints: {
              767: {
                slidesPerView: 1
              }
            }
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'has_section_success_stories': {
      init: function() {
        var successSwiper = new Swiper('.section-success_stories .swiper-container', {
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          uniqueNavElements: false,
          //slidesPerView: 1,
          autoHeight: true,
          speed: 1000,
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
